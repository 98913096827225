import { render, staticRenderFns } from "./PdfPage.vue?vue&type=template&id=71a71524&scoped=true&"
import script from "./PdfPage.vue?vue&type=script&lang=js&"
export * from "./PdfPage.vue?vue&type=script&lang=js&"
import style0 from "./PdfPage.vue?vue&type=style&index=0&id=71a71524&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71a71524",
  null
  
)

component.options.__file = "PdfPage.vue"
export default component.exports